<template>
  <div v-frag>
    <h2>Registrazione completata</h2>
    <section>
      Si prega di controllare l'email. Abbiamo inviato un messaggio
      all'indirizzo indicato nel modulo di registrazione.<br />
      Cliccare sul link contenuto all'interno del messaggio per verificare
      l'indirizzo email, impostare le credenziali di accesso e completare la
      procedura di accesso al servizio.
    </section>
  </div>
</template>

<script>
const NAME = "RegistrazioneAvvenutaSpSa";
export default {
  name: NAME,
};
</script>