<template>
  <LayoutSpSa>
    <v-card id="registrazione">
      <a id="torna-login" href="#/login">Tornare al Login</a>
      <v-img
        id="logo-mida4"
        src="@/assets/logoMida4Ext.png"
        alt="Logo Mida4"
      ></v-img>
      <v-card-title>Benvenuto su Invio Diretto Spese Sanitarie</v-card-title>
      <FormUtenteSpSa
        v-if="!registrazioneCompletata"
        :parent="NAME"
        :edit="true"
        @registrazione="mostraMessaggio"
      />
      <RegistrazioneAvvenutaSpSa v-else />
    </v-card>
  </LayoutSpSa>
</template>
<script>
const NAME = "RegistrazioneSpSa";

import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import FormUtenteSpSa from "@/components/_common/FormUtenteSpSa";
import RegistrazioneAvvenutaSpSa from "@/components/RegistrazioneSpSa/RegistrazioneAvvenutaSpSa";

export default {
  name: NAME,
  data() {
    return {
      NAME: NAME,
      registrazioneCompletata: false,
    };
  },
  components: {
    LayoutSpSa,
    FormUtenteSpSa,
    RegistrazioneAvvenutaSpSa,
  },
  methods: {
    mostraMessaggio() {
      this.registrazioneCompletata = true;
    },
  },
};
</script>
<style src="@/styles/custom/views/_registrazione.scss" lang="scss"></style>

